<template>
  <div>
    <b-modal no-close-on-backdrop :id="id" size="lg" :title="`${this.$route.params.userId ? 'Update' : 'Request'} Holiday`" @hidden="closeModal">
      <b-row>
        <b-col cols="12" v-if="!loading && error">
          <b-alert variant="danger">{{ error }}</b-alert>
        </b-col>
        <b-col class="text-center" cols="12" v-if="loading">
          <b-spinner variant="primary"></b-spinner>
        </b-col>
      </b-row>
      <b-row v-if="!loading && !error">
        <b-col cols="12">
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="First Day" :invalid-feedback="validationErrors.start_date" :state="!validationErrors.start_date">
                <b-form-datepicker v-model="editedHoliday.start_date" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Last Day" :invalid-feedback="validationErrors.end_date" :state="!validationErrors.end_date">
                <b-form-datepicker v-model="editedHoliday.end_date" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Working Days" :invalid-feedback="validationErrors.working_days" :state="!validationErrors.working_days">
                <b-form-input type="number" v-model="editedHoliday.working_days" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Notes" :invalid-feedback="validationErrors.note" :state="!validationErrors.note">
                <b-form-textarea type="number" v-model="editedHoliday.note" />
              </b-form-group>
              <p class="mb-0 text-muted"><small>If you're taking a half day or only a specific part of a day off, you might want to mention this in the notes.</small></p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <template #modal-footer>
        <p class="text-muted mr-4"><small>You currently have {{ user.remaining_holiday_allowance }} days of holiday allowance remaining this year.</small></p>
        <b-button @click.prevent="closeModal" variant="light">Close</b-button>
        <b-button @click.prevent="saveAbsence" variant="primary">
          <b-spinner class="mx-4" variant="light" small v-if="saving"/>
          <span v-if="!saving">{{ $route.params.userId ? 'Update' : 'Request' }}</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      validationErrors: {},
      genericError: undefined,
      editedHoliday: {
        start_date: moment().add(14, 'days').toDate(),
        working_days: 1,
        end_date: moment().add(14, 'days').toDate(),
        note: '',
      },
      loading: false,
      saving: false,
      error: '',
    };
  },
  props: {
    absence: Object,
    id: String,
  },
  async mounted() {
    if (this.$route.params.absenceId && this.absence) {
      this.loading = true;

      try {
        const absence = await this.fetchSingle(this.$route.params.absenceId);
        this.editedHoliday = absence;
        this.loading = false;
      } catch (err) {
        this.error = "Can not load absence. Please try again later.";
        this.loading = false;
      }
    }
  },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapActions('absences', ['fetch', 'fetchSingle', 'updateOrCreate']),
    closeModal() {
      this.validationErrors = {};
      if (this.$route.name !== this.$route.meta.parentName) {
        this.$router.push({ name: this.$route.meta.parentName });
      }
    },
    saveAbsence() {
      this.saving = true;
      this.updateOrCreate({
        type: 'holiday',
        absentable_id: this.user.id,
        ...this.editedHoliday
      }).then(() => {
        this.saving = false;
        this.genericError = undefined;
        this.closeModal();
        return this.fetch();
      }).catch(err => {
        if (err.response && err.response.status === 422) {
          this.saving = false;
          err.response.json().then(json => {
            this.validationErrors = json.errors;
          });
        } else {
          this.saving = false;
          this.genericError = 'There was an error saving just now. Please try again later.';
        }
      });
    }
  },
  watch: {
    user(value) {
      this.editedUser = value;
    }
  }
}
</script>
